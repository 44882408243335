import { useEffect, useState } from "react";
import { getNewsInner } from "../Service/Api";
import Header from '../Component/Header';
import { Link } from 'react-router-dom';
import Footer from '../Component/Footer';

const News = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All");
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getNewsInner();
            setData(galleryData);

            const uniqueYears = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.fromDate) {
                        const year = new Date(item.fromDate).getFullYear();
                        return [year];
                    } else {
                        return [];
                    }
                }))
            );
            const uniqueYearRanges = uniqueYears.map(year => `${year}`);
            uniqueYearRanges.sort((a, b) => b - a);
            setYearRanges(uniqueYearRanges);

            const uniqueMonths = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.fromDate) {
                        const month = new Date(item.fromDate).getMonth();
                        return [month];
                    } else {
                        return [];
                    }
                }))
            ).sort((a, b) => a - b);

            setMonthOptions(uniqueMonths.map(month => monthNames[month]));

            if (selectedYear === "All") {
                setFilteredData(galleryData);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        let filteredData = data;

        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.fromDate && item.fromDate.trim() !== "") {
                    const year = new Date(item.fromDate).getFullYear();
                    return parseInt(selectedYear) === year;
                }
                return false;
            });
        }

        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.fromDate && item.fromDate.trim() !== "") {
                    const month = new Date(item.fromDate).getMonth() + 1;
                    return month === parseInt(selectedMonth);
                }
                return false;
            });
        }

        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        filteredData.sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate));

        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);

    const emptyArray = [
        {
            description: "“Educating the mind without educating the heart is no education at all.” Sacred Heart has been educating boys and girls since 1990...",
        }
    ];

    return (
        <>
            <Header />
            <div className="innerslide Admissionbg">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link></li>
                    <li> News </li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1> News  </h1>
                        </div>
                    </div>
                    <div className="row tabs-dynamic">
                        <div className="col-md-3">
                            <div className="count-val">
                                <p>
                                    Total Count: {filterData.length}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="month-selection">
                                <select
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className="yearSelection">
                                    <option value={"All"}>All</option>
                                    {yearRanges.map((yr) => (
                                        <option key={yr} value={yr}>{yr}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="month-selection">
                                <select
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                    className="monthSelect">
                                    <option value={"All"}>All</option>
                                    {monthOptions.map((month, index) => (
                                        <option key={index} value={index + 1}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="searchBox">
                                <input
                                    type="text"
                                    id="myInput"
                                    name="name"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder="Search Here.."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row years year2023 showYear">
                        {filterData.length > 0 ? (
                            filterData.map((item, index) => (
                                <div className="col-md-6 newsCount" key={index}>
                                    <div className="news-blk">
                                        <div className="news-head">
                                            <h2>{new Date(item.fromDate).getDate()}<span>{new Date(item.fromDate).toLocaleString('default', { month: 'short' })}</span> </h2>
                                            <h3>{item.title}</h3>
                                        </div>
                                        <div className="news-content">
                                            <p>{item.description}</p>
                                        </div>
                                        {item.attachments && item.attachments.length > 0 && (
                                            <div className="attachment">
                                                <Link to={`https://webapi.entab.info/api/image/${item?.attachments[0]}`} target="_blank"><i className="bi bi-paperclip"></i></Link>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            emptyArray.map((data, index) => (
                                <div className="col-md-6 newsCount" key={index}>
                                    <div className="news-blk">
                                        <div className="news-head">
                                            <h2>DD<span>MM</span></h2>
                                            <h3>News Heading</h3>
                                        </div>
                                        <div className="news-content">
                                            <p>Stay connected for more updates!</p>
                                        </div>
                                        <div className="attachment">
                                            <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default News;
