import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const Pledge = () => {
  return (
     <>
       <Header/>
        <div className="innerslide Pledgebg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>
                <li> School </li>
                <li> Pledge </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Pledge </h1>
                         <p>India is my country and all Indians are my brothers and sisters.</p>
<p>I love my country and I am proud of its rich and varied heritage.</p>
<p>I shall always strive to be worthy of it.</p>
<p>I shall give respect to my parents, teachers and elders and treat everyone with courtesy.</p>
<p>To my country and my people, I pledge my devotion.</p>
<p>In their well-being and prosperity alone, lies my happiness.</p>
<p>Jai Hind!</p> 
                    </div>
                </div>
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default Pledge
